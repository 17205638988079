export default function MoodsLogo({ className }) {
    return (
      <svg className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 170.08 42.52" xmlSpace="preserve">
        <title>Moods Oy</title>
        <g>
          <path d="M3.25,38.81h6.07V21.17
            c0-3.39,2.16-5.32,4.67-5.32c2.92,0,5.08,1.75,5.08,6.13v16.82h6.07V21.98c0-4.38,2.16-6.13,5.08-6.13c2.51,0,4.67,1.93,4.67,5.32
            v17.64h6.07v-17.7c0-7.18-4.79-11.33-10.34-11.33c-4.26,0-7.36,2.04-8.47,5.02h-0.12c-1.11-2.98-4.21-5.02-8.47-5.02
            c-5.55,0-10.34,4.15-10.34,11.33V38.81z"></path>
          <path d="M67.94,24.67
            c0,5.08-3.85,9.17-9.17,9.17c-5.32,0-9.17-4.09-9.17-9.17s3.85-9.17,9.17-9.17C64.08,15.5,67.94,19.59,67.94,24.67 M74.36,24.67
            c0-7.48-5.72-14.89-15.59-14.89c-9.87,0-15.59,7.42-15.59,14.89s5.72,14.89,15.59,14.89C68.64,39.56,74.36,32.15,74.36,24.67"></path>
          <path d="M101.18,24.67
            c0,5.08-3.85,9.17-9.17,9.17c-5.32,0-9.17-4.09-9.17-9.17s3.85-9.17,9.17-9.17C97.32,15.5,101.18,19.59,101.18,24.67 M107.6,24.67
            c0-7.48-5.72-14.89-15.59-14.89c-9.87,0-15.59,7.42-15.59,14.89s5.72,14.89,15.59,14.89C101.88,39.56,107.6,32.15,107.6,24.67"></path>
          <path d="M133.69,24.67
            c0,5.08-4.09,9.17-8.88,9.17c-4.91,0-8.99-4.09-8.99-9.17c0-5.08,4.09-9.17,8.99-9.17C129.6,15.5,133.69,19.59,133.69,24.67
            M139.53,38.81V2.13h-6.07v11.86h-0.12c-1.58-2.4-4.79-4.21-8.99-4.21c-9.17,0-14.95,7.94-14.95,14.89
            c0,7.3,5.37,14.89,14.66,14.89c3.85,0,7.36-1.63,9.29-4.21h0.12v3.45H139.53z"></path>
          <path d="M141.78,34.6
            c3.85,3.27,7.94,4.96,12.73,4.96c8.59,0,11.97-5.37,11.97-9.52c0-4.21-2.45-6.6-7.48-7.53l-6.13-1.17
            c-2.34-0.41-3.68-1.29-3.68-2.98c0-2.28,2.57-3.56,5.2-3.56c1.99,0,4.61,0.64,7.59,3.15l3.45-4.26c-3.85-2.74-7.3-3.91-11.21-3.91
            c-7.07,0-11.1,4.44-11.1,9.23c0,4.03,2.22,6.6,10.16,8.06l3.45,0.64c2.22,0.41,3.68,1.4,3.68,3.27c0,1.58-2.1,3.56-5.61,3.56
            c-3.15,0-6.72-1.81-8.88-4.15L141.78,34.6z"></path>
        </g>
      </svg>
    );
  }