import { useState, useRef, useEffect } from "react";
import useWindowSize from '../hooks/useWindowSize'

export default function SlideShow() {
  const windowSize = useWindowSize();
  const itemsRef = useRef([]);
  const [highestSlideHeight, setHighestSlideHeight] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [fadeImages] = useState([
    {
      content:
        "Petri järjestää suositut musiikkifestivaalit, jotka viettävät juhlavuotta tulevana kesänä. Juhlavuoden kunniaksi tapahtumaan on tulossa tunnettuja esiintyjiä ympäri maailmaa. Petri haluaa panostaa festivaalien markkinointiin, ja toivoo saavansa sisältöä monikanavaisesti sovellukseen, verkkosivuille ja somekanaviin. Tapahtuman verkkosivujen tulee myös olla sellaiset, että ne kestävät hetkellisen kävijämäärän nousun, kun liput tapahtumaan tulevat myyntiin. Petri päätyy Headless WordPress -ratkaisuun, sillä hän haluaa tarjota helpon käyttäjäkokemuksen lippujen ostajille, toivoo näkyvyyttä monikanavaisesti eikä hän halua, että verkkosivut latautuvat hitaasti suuren kävijämäärän kasvun takia.",
      caption: "Verkkosivuille toivotaan kestävyyttä kovassakin ruuhkassa",
    },
    {
      content:
        "Kallen yrityksessä on paljon työntekijöitä, ja hän toivoo, että etenkin intranettiin saataisiin päivitettyä sisältöä helposti ja sujuvasti yhdestä käyttöjärjestelmästä. Verkkosivujen tulisi olla tietoturvalliset, sillä niissä käsitellään asiakkaiden yksityistietoja. Myös hakukoneystävällisyyteen halutaan panostaa, jotta uudet asiakkaat löytäisivät yrityksen helposti. Kalle päättää valita Headless WordPress -verkkosivut, sillä ne palvelevat suuryrityksen monikanavaisia tarpeita ja tarjoavat asiakkaille sujuvan ja luotettavan käyttökokemuksen",
      caption: "Suuryritys kaipaa vaivattomuutta ja vahvaa tietoturvaa",
    },
    {
      content:
        "Tarja on personal trainer ja hän on perustanut yrityksen, jonka liiketoiminta on vielä pientä. Häneltä löytyy verkkosivut ja somekanavat yritykselleen. Tulevaisuudessa Tarja haluaisi valmennuksen tueksi sovelluksen, jonka kautta hän voi jakaa treenivideoita, reseptejä ja keskustella asiakkaidensa kanssa. Tarja haluaa myös verkkosivut, jotka toimisivat nopeammin ja omaisivat paremman tietoturvan. Tällä hetkellä Tarjalle sopisi vielä perinteinen verkkosivumalli, ja hän pohtii kumman järjestelmän valitsee. Hän haluaa kuitenkin panostaa tulevaisuuteen ja liiketoiminnan kehittämiseen, ja valitsee Headless WP -järjestelmän.",
      caption: "Headless WordPress palvelee tulevaisuuden visioita",
    },
  ]);

  function adjustSlideHeight() {
    let tmpHighestSlideHeight = 0;
    for (let i in itemsRef.current) {
      if (itemsRef.current[i].clientHeight > tmpHighestSlideHeight) {
        tmpHighestSlideHeight = itemsRef.current[i].clientHeight;
      }
    }
    setHighestSlideHeight(tmpHighestSlideHeight);
  }

  useEffect(() => {
    // Add event listener
    window.addEventListener("load", adjustSlideHeight);

    // Call handler right away so state gets updated with initial window size
    adjustSlideHeight();

    // Remove event listener on cleanup
    return () => window.removeEventListener("load", adjustSlideHeight);
  }, []); // Empty array ensures that effect is only run on mount

  useEffect(() => {
    adjustSlideHeight();
  }, [windowSize.width, windowSize.height]);

  function nextButton() {
    if (currentSlide < fadeImages.length - 1) setCurrentSlide(currentSlide + 1);
  }
  function previousButton() {
    if (currentSlide > 0) setCurrentSlide(currentSlide - 1);
  }
  return (
    <div className="slideshow">
      <div className="slide-container">
        <div className="slide-controls">
          <button className="previous" aria-label="Previous" onClick={() => previousButton()}>
            <svg viewBox="0 0 24 24" height="25px" width="2em">
              <polyline
                stroke="white"
                strokeWidth="4"
                points="7 2 17 12 7 22"
                transform="matrix(-1 0 0 1 24 0)"
              ></polyline>
            </svg>
          </button>
        </div>

        <div style={{ height: highestSlideHeight, position: 'relative', width: '100%' }}>
          {fadeImages.map(function (item, i) {
            return (
              <div className={`slide-text ${i === currentSlide ? "current-slide" : ""}`} key={i} ref={(el) => itemsRef.current[i] = el}>
                <div className="slide-caption">
                  <h2>{item.caption}</h2>
                </div>
                <div className="slide-content">
                  <p>{item.content}</p>
                </div>
              </div>
            )
          })}
        </div>

        <div className="slide-controls">
          <button className="next" aria-label="Next" onClick={() => nextButton()}>
            <svg viewBox="0 0 24 24" height="25px" width="2em">
              <polyline
                stroke="white"
                strokeWidth="4"
                points="7 2 17 12 7 22"
              ></polyline>
            </svg>
          </button>
        </div>
      </div>
      <div className="indicator-container">
        {fadeImages.map(function (item, i) {
          return (
            <div
              key={i}
              className={`indicator ${i === currentSlide ? " current-indicator" : ""}`}
              onClick={() => setCurrentSlide(i)}
            >
              <div className="ind">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 24 24"
                  height="1em"
                  width="1em"
                >
                  <g>
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <circle cx="12" cy="12" r="10"></circle>
                  </g>
                </svg>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
